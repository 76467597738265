.prices-section {
    display: flex;
    flex-direction: column;

    .title-row {
        display: flex;
        align-items: flex-end;
        margin: r(40) 0;
    }

    .btns {
        display: flex;
        gap: r(10);
        margin-left: 24%;
    }

    .price-description {
        grid-column: span 2;
    }

    .price-tab {
        display: grid;
        grid-template-columns: 41.1% 1fr;
        gap: r(40);
        height: 100%;
        overflow: auto;

        &:not(.active) {
            display: none;
        }

        .form-input-wrap {
            &:not(.long) {
                display: flex;
                align-items: center;

                .form-input {
                    width: r(95);
                    flex: 0 0 auto;
                    color: $red;
                    text-align: center;
                }

                .form-placeholder {
                    order: 1;
                    margin-left: r(16);
                }
            }

            .price {
                display: block;
                font-size: r(18);
                color: $red;
            }

            &.long {
                margin-bottom: r(10);
                grid-column: span 2;
            }
        }

        .info {
            margin: 0 0 r(25);
            padding: r(44) r(22) r(35) r(26);
            border: 1px solid #c8c8c8;
            align-self: flex-start;
            position: sticky;
            top: 0;
            left: 0;

            .output {
                margin-top: r(20);
            }

            .bottom {
                margin: r(29) 0 0;
                padding: r(25) 0 0;
                border-top: 2px dashed #c8c8c8;
            }

            .price-row {
                display: flex;
                align-items: flex-start;

                .price {
                    margin-right: r(28);
                }
            }

            .total-text {
                width: 65%;
                margin-bottom: r(16);
                font-size: r(16);
                font-style: italic;
                font-weight: 500;
            }
        }

        .name-wrap {
            position: relative;
            padding: r(27) 0 r(24);

            .cloud {
                position: absolute;
                top: 0;
                left: r(-11);
                width: r(362);
                height: r(87);
                z-index: -1;
                fill: #fff;
            }
        }

        .program-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: r(6) r(36);
            margin-top: r(10);

            .program {
                display: flex;
                align-items: center;
                font-size: r(18);
                font-weight: 500;
                color: $red;
                line-height: 1.4;
                font-style: italic;

                .star {
                    width: r(7);
                    height: r(7);
                    margin-right: r(12);
                    fill: $red;
                }
            }
        }

        .fields {
            display: grid;
            grid-template-columns: 62% 1fr;
            gap: r(6) r(26);
            margin: r(20) 0 0;
        }

        .additional-services {
            display: flex;
            flex-direction: column;
            gap: r(16);
            padding-right: r(15);

            .selected-row {
                padding: 0 r(20) 0;

                span {
                    color: $red;
                    font-weight: 500;
                    font-style: italic;
                }
            }

            .service-row {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: r(14) r(20);
                border: 1px solid #c8c8c8;

                .name {
                    flex: 1 1 69.25%;
                }

                .price-block {
                    display: flex;
                    align-items: center;
                    margin: 0 r(20) 0 r(30);
                    flex: 0 0 auto;
                }

                .price-type {
                    margin-left: r(6);
                    font-size: r(16);
                    font-style: italic;
                    font-weight: 500;
                    opacity: 0.5;
                }

                .add-btn {
                    flex: 0 0 r(155);

                    .icon {
                        flex: 0 0 auto;
                        margin-right: r(10);
                        fill: $red;
                    }

                    .ok {
                        width: r(15);
                        height: r(11);
                    }

                    .plus {
                        width: r(12);
                        height: r(12);
                    }

                    &:not(.added) {
                        .ok {
                            display: none;
                        }
                    }

                    &.added {
                        .plus {
                            display: none;
                        }
                    }
                }

                &.selected {
                    border-color: $red;
                    background: rgba(227, 51, 40, 0.04);
                }
            }
        }
    }

    @media (max-width: 1279px) {
        .title-row {
            flex-direction: column;
            align-items: flex-start;
        }

        .btns {
            margin: r(30) 0 0;
            flex-direction: column;
            align-items: flex-start;
        }

        .price-description {
            grid-column: auto
        }

        .price-tab {
            grid-template-columns: 1fr;

            .program-list {
                grid-template-columns: 1fr
            }

            .fields {
                grid-template-columns: 1fr
            }

            .form-input-wrap {
                &.long {
                    grid-column: auto;
                }
            }

            .info {
                padding-left: r(12);
                padding-right: r(12);

            }

            .name-wrap {
                padding: r(10) 0;

                .cloud {
                    width: r(217);
                    height: r(52);
                    left: 0;
                }
            }

            .additional-services {
                .service-row {
                    flex-direction: column;
                    align-items: flex-start;

                    .add-btn {
                        flex: 0 0 auto;
                    }

                    .price-block {
                        margin: r(10) 0;
                    }
                }
            }
        }
    }

    ;
}