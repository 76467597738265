
.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-visible {
    overflow: visible;
  }
}
.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.collapse {
  overflow: hidden;
  transition: height var(--collapse-time, #{$tr-time * 2});
  &.is-collapsed {
    height: 0;
  }
}

.link-cover {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

.hidden {
  display: none !important;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: r(8) r(16);
  text-align: center;
  font-size: r(18);
  font-weight: 500;
  color: var(--text-color) !important;
  text-decoration: none !important;
  border: 1px solid var(--border-color);
  border-radius: r(42);
  background: var(--bg-color);
  cursor: pointer;
  &-red {
    --border-color: #{$red};
    --text-color: #fff;
    --bg-color: #{$red};
    &:hover {
      --border-color: #d0e8f0;
      --text-color: #{$black};
      --bg-color: #d0e8f0;
    }
  }
  &-red-border {
    --border-color: #{$red};
    --text-color: #{$black};
    --bg-color: transparent;
    &:hover {
      --border-color: #d0e8f0;
      --text-color: #{$black};
      --bg-color: #d0e8f0;
    }
  }
  &-red-border2 {
    --border-color: #{$red};
    --text-color: #5e5f5e;
    --bg-color: transparent;
    &:hover {
      --border-color: #d0e8f0;
      --text-color: #{$black};
      --bg-color: #d0e8f0;
    }
  }
  &-blue {
    --border-color: #0171ac;
    --text-color: #fff;
    --bg-color: #0171ac;
    &:hover {
      --border-color: #d0e8f0;
      --text-color: #{$black};
      --bg-color: #d0e8f0;
    }
  }
  &-blue-border {
    --border-color: #0171ac;
    --text-color: #{$black};
    --bg-color: transparent;
    &:hover {
      --border-color: #d0e8f0;
      --text-color: #{$black};
      --bg-color: #d0e8f0;
    }
  }
  &-red-white {
    --border-color: #{$red};
    --text-color: #{$black};
    --bg-color: #fff;
    &:hover {
      --border-color: #d0e8f0;
      --text-color: #{$black};
      --bg-color: #d0e8f0;
    }
  }

  &:not(:disabled) {
    transition-duration: $tr-time;
    transition-property: color, border-color, background-color, box-shadow;
  }
  &:disabled {
    pointer-events: none;
    transition-duration: 0ms;
    --text-color: transparent;
  }
}

.submit-btn {
  --form-status-icon-color: #fff;
  --form-status-scale: 0.6;
  .preloader,
  .ok-icon {
    display: none;
  }
  &.htmx-request {
    .preloader {
      display: block;
    }
  }
  &.success {
    .ok-icon {
      display: block;
    }
  }
  &:disabled,
  &.htmx-request,
  &.success {
    pointer-events: none;
    transition-duration: 0ms;
    --text-color: transparent;
  }
}

.select-btn {
  --border-color: #{$red};
  --text-color: #{$black};
  --bg-color: transparent;
  &:hover {
    --border-color: #d0e8f0;
    --text-color: #{$black};
    --bg-color: #d0e8f0;
  }
  .icon {
    margin-right: r(10);
    fill: currentColor;
  }
  .plus {
    width: r(12);
    height: r(12);
  }
  .ok {
    width: r(14);
    height: r(10);
  }
  &.selected {
    --bg-color: #{$red};
    --text-color: #fff;
    --border-color: #{$red};
    .plus {
      display: none;
    }
  }
  &:not(.selected) {
    .ok {
      display: none;
    }
  }
}

.screen-container {
  height: calc(100vh - r(84) - r(32));
}

.font-nunito {
  font-family: "Nunito", sans-serif;
}

.font-pacifico {
  font-family: "Pacifico", serif;
}

.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 94.8%;
  padding-left: r(25);
  padding-right: r(25);
}
.text-big {
  font-size: 1.2rem;
}
.text-h1 {
  font-size: r(52);
  line-height: 1;
}
.text-h2 {
  font-size: r(36);
  line-height: 1;
}
.text-h3 {
  font-size: r(28);
  font-weight: 700;
  line-height: 1.2;
}
.text {
  font-size: r(18);
}
.text-small {
  font-size: r(14);
  line-height: 1.4;
}
.page-bottom-padding {
  padding-bottom: r(70);
}
.red-color {
  color: $red;
}
.round-borders {
  border-radius: r(10);
}

@media (max-width: 1279px) {
  .btn {
    font-size: r(14);
  }
  .container {
    max-width: 100%;
    padding: 0 r(14);
  }
  .text-h1 {
    font-size: r(36);
  }
  .text-h3 {
    font-size: r(24);
  }
  .screen-container {
    height: auto;
  }
  .text {
    font-size: r(16);
  }
}
