.filters-form {
  padding: 0 0 r(50);
  .radio-label {
    padding: r(6) r(12);
    flex: 0 0 auto;
    font-size: r(14);
    line-height: 1.4;
    background: #fff;
    border-radius: r(20);
    cursor: pointer;
    input {
      display: none;
    }
    &:has(:checked) {
      background: $red;
      color: #fff;
    }
  }
  .prop-group {
    &:not(:last-child) {
      margin-bottom: r(24);
    }
  }
  .form-placeholder {
    margin-bottom: r(5);
  }
  .radios-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: r(8);
  }
  .range-wrap {
    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .form-input-wrap {
        flex: 0 0 36.9%;
      }
      .delimeter {
        height: 1px;
        flex: 0 0 11%;
        background: #c8c8c8;
      }
    }
  }
  .range-slider {
    margin-top: r(16);
  }
}
