.content-text {
    h1 {
        font-size: r(52);
    }

    h2 {
        font-size: r(36);
    }

    h3 {
        font-size: r(28);
    }

    h4 {
        font-size: r(24);
    }

    h1,
    h2 {
        font-family: "Pacifico", ui-serif;
        line-height: 1;
    }

    h3,
    h4 {
        font-weight: 700;
    }

    h1,
    h2,
    h3,
    h4 {
        margin: r(80) 0 r(30);
    }

    p {
        margin-bottom: r(22);
    }

    p,
    li {
        font-size: r(18);
        line-height: 1.4;
    }

    a {
        color: $black;
        text-decoration: none;
        border-bottom: 2px solid #969aa8;

        &:hover {
            border-color: $red;
        }
    }

    img {
        display: block;
        margin: r(60) auto;
        max-width: 100%;
        width: auto !important;
        height: auto !important;
    }

    ul,
    ol {
        margin: r(20) 0 r(30);
        padding-left: r(24);

        img {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            border-radius: 0;
        }
    }

    li {
        &:not(:last-child) {
            margin-bottom: r(20);
        }
    }

    ul {
        list-style: none;

        li {
            position: relative;

            &::before {
                content: "";
                width: r(14);
                height: r(14);
                position: absolute;
                left: r(-24);
                top: r(4);
                border: 3px solid $red;
                border-radius: 50%;
            }
        }
    }

    ol {
        list-style: decimal outside;

        li::marker {
            font-size: r(20);
            color: $red;
            font-weight: 700;
        }
    }

    .table-wrap {
        border-top: 1px solid $light-gray;
        border-bottom: 1px solid $light-gray;
        overflow: auto;
    }

    table {
        min-width: 100%;
        border: 0;
        border-spacing: 0;
        border-collapse: collapse;

        td,
        th {
            padding: r(25) r(36);
            font-size: r(18);
            line-height: 1.4;
            border: 1px solid $light-gray;

            &:first-child {
                border-left: 0;
            }

            &:last-child {
                border-right: 0;
            }
        }

        tr {
            &:first-child {
                td {
                    border-top: 0;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        p {
            margin: 0;
        }
    }

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 1279px) {
    .content-text {
        h1 {
            font-size: r(26);
        }

        h2 {
            font-size: r(21);
        }

        h3 {
            font-size: r(14);
        }

        h1,
        h2,
        h3,
        h4 {
            margin: r(32) 0 r(16);
        }

        h1,
        h2 {
            text-transform: none;
        }

        p,
        li {
            font-size: r(14);
            line-height: 1.62;
        }

        p {
            margin-bottom: r(8);
        }

        ol,
        ul {
            margin: r(8) 0;
        }

        li {
            &:not(:last-child) {
                margin-bottom: r(12);
            }
        }

        table {

            td,
            th {
                padding: r(16) r(20);
                font-size: r(14);
            }
        }

        .table-wrap {
            border-radius: r(8);
        }
    }
}