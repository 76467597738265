.tour-card {
  display: flex;
  position: relative;
  background: #fff;
  .img-col {
    flex: 0 0 35.2%;
    .img {
      width: 100%;
    }
  }
  .text-col {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: r(30) r(25) r(30) r(36);
  }
  .duration {
    margin-bottom: r(8);
    font-size: r(16);
  }
  .title-wrap {
    padding: 0 0 r(24) r(10);
  }
  .price-row {
    display: flex;
    align-items: flex-start;
    padding-top: r(26);
    margin-top: auto;
  }
  .price {
    margin-right: r(28);
  }
  .to-cart {
    margin-left: r(10);
  }
  .program-composition {
    display: flex;
    flex-wrap: wrap;
    gap: r(10);
  }
  .composition {
    padding: r(6) r(12);
    font-size: r(16);
    line-height: 1.4;
    border-radius: r(20);
    background: #f4f7f9;
  }
  &:not(:last-child) {
    margin-bottom: r(20);
  }

  @media (max-width: 1279px){
    .price-row{
      flex-wrap: wrap;
    }
    .price{
      margin: 0 0 r(10);
      flex: 0 0 100%;
    }
  }

  @media (max-width: 767px){
    flex-direction: column;
    .text-col{
      padding: r(30);
    }
    .title-wrap{
      padding: 0 0 r(20);
    }    
  }

  @media (max-width: 374px){
    .price-row{
      flex-direction: column;
      align-items: stretch;
    }
    .to-cart{
      width: 100%;
      margin: r(10) 0 0;
    }
  }
}
