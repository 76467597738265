.objects-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: r(50);
  .left-col {
    padding: 0 0 r(70) r(75);
  }
  .content-wrap {
    display: flex;
    gap: r(65);
  }
  .buttons-col {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .object-toggle {
      max-width: r(222);
      padding: r(17) r(34) 0 r(17);
      margin-left: r(-17);
      position: relative;
      text-align: left;
      .cloud {
        width: 100%;
        height: calc(100% + r(17));
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        fill: #fff;
        opacity: 0;
      }
      &:hover {
        color: $red;
        .cloud {
          opacity: 1;
        }
      }
    }
  }
  .text-col {
    flex-grow: 1;
  }
  .title {
    margin: r(40) 0;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .right-col {
    height: calc(100dvh - #{r(32)} - #{r(84)});
    position: sticky;
    top: 0;
  }
  .blizzard {
    width: r(587);
    height: r(348);
    position: absolute;
    bottom: r(-16);
    left: 0;
    transform: translate(-70%, 0);
    z-index: -1;
    fill: rgba(#d0e8f0, 0.9);
  }
  @media (min-width: 1280px) {
    .mobile-content {
      display: none;
    }
  }
  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
    .left-col {
      padding: 0 r(14);
    }
    .content-wrap {
      display: block;
    }
    .buttons-col {
      .object-toggle {
        margin: 0;
      }
    }
    .text-col,
    .right-col {
      display: none;
    }
    .mobile-content {
      padding: r(40) 0 0;
      .image {
        margin-bottom: r(18);
      }
    }
    .image {
      height: auto;
    }
  }
}
