.breadcrumbs {
  padding: r(12) 0 0;
  ul {
    padding-left: r(100);
    li {
      display: inline-flex;
      align-items: center;
      font-size: r(14);
      font-style: italic;
      font-weight: 500;
      a {
        transition-property: opacity, color;
        &:not(:hover) {
          opacity: 0.5;
        }
      }
      &:not(:last-child) {
        margin-right: r(10);
        &::after {
          content: "";
          display: block;
          width: r(22);
          height: r(12);
          margin-left: r(10);
          background: url("../images/breadcrumb-arrow.svg") center/cover
            no-repeat;
        }
      }
      &:last-child {
        position: relative;
        overflow-x: clip;
        &::after {
          content: "";
          position: absolute;
          bottom: r(-4);
          left: 0;
          width: r(212);
          height: r(6);
          background: url("../images/breadcrumb-underline.svg") center/cover
            no-repeat;
        }
      }
    }
  }
  @media (max-width: 1279px) {
    padding-top: r(26);
    ul {
      padding-left: 0;
      li {
        &:not(:last-child) {
          margin-right: r(5);
          &::after {
            width: r(17);
            height: r(9);
            margin-left: r(5);
          }
        }
      }
    }
  }
}
