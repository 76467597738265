@media (max-width: 1440px) {
  // .btn-red {
  //   width: 160px;
  // }
  
  #ticket {
    margin-left: 0.8666666667rem !important;
  }

  .btn-red .icon {
    max-width: 30px;
    max-height: 30px;

    }

    // .btn {
    //   font-size: 12px !important;
    // }

    .header .logo .name {
      margin-left: 0 !important;
      font-size: 1.6rem !important;
  }
}

#icon {
  top: -2rem !important;
}
.btn {
  padding: 0.3333333333rem 0.4rem !important;
}
.btn-red {
  // max-width: 180px;
  max-height: 66px;

}

#ticket {
  margin-left: 15px;
}

.btn-red .icon {
max-width: 50px;
}

.header {
  height: r(84);
  position: relative;
  z-index: 10;
  background: #fff;
  .container {
    display: flex;
    align-items: center;
  }
  .logo {
    display: block;
    position: relative;
    padding-left: r(99);
    .icon {
      width: r(99);
      height: r(103);
      position: absolute;
      top: 0;
      left: 0;
      &.mobile {
        display: none;
      }
    }
    .name {
      margin-left: r(13);
      font-size: r(30);
      color: $red;
    }
  }
  .book-trip {
    // margin-left: r(74);
    .icon {
      width: r(48);
      height: r(38);
      position: absolute;
      top: r(-22);
      right: r(-10);
      transition-property: transform, opacity;
      transition-duration: 0.3s;
    }
    &:not(:hover) {
      .icon {
        opacity: 0;
        transform: scale(0.3);
      }
    }
  }
  .work-hours {
    margin-left: r(8);
    .icon {
      width: r(43);
      height: r(53);
      position: absolute;
      top: r(-20);
      right: r(-10);
      transition-property: transform, opacity;
      transition-duration: 0.3s;
    }
    &:not(:hover) {
      .icon {
        opacity: 0;
        transform: scale(0.3);
      }
    }
  }
  .menu {
    display: flex;
    // margin-left: auto;
    .parent {
      position: relative;
      z-index: 1;
      transition: background-color $tr-time;
      .star-icon {
        width: r(7);
        height: r(7);
        margin-left: r(4);
        fill: $red;
      }
      &:hover {
        background: #f4f7f9;
        .submenu {
          display: block;
        }
      }
    }
    .submenu {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      padding: r(20) r(14);
      background: #fff;
      border-radius: 0 0 r(5) r(5);
    }
    .child {
      display: block;
      white-space: nowrap;
      &:not(:last-child) {
        margin-bottom: r(12);
      }
    }
    .link {
      display: flex;
      align-items: center;
      padding: r(30) r(12);
    }
  }
  .menu-toggle {
    width: r(16);
    .burger {
      width: r(16);
      height: r(12);
      fill: $black;
    }
    .close {
      display: none;
      width: r(18);
      height: r(14);
    }
    &.open {
      .burger {
        display: none;
      }
      .close {
        display: block;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    gap: r(10);
    position: absolute;
    right: r(75);
    top: 100%;
    transform: translate(0, -50%);
    .btn {
      .icon {
        width: r(20);
        height: r(20);
        margin-left: r(10);
        fill: transparent;
      }
    }
  }
  .main-tel{
    margin: 0 auto;
    .phone{
      display: block;
      margin-bottom: r(4);
    }
  }
}

@media (max-width: 1279px) {
  .header {
    height: r(60);
    padding: r(9) 0 0;
    .book-trip,
    .work-hours,
    .menu, .main-tel, .btn-red {
      display: none;
    }
    .logo {
      padding-left: r(40);
      .icon {
        width: r(40);
        height: r(54);
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
      .name {
        font-size: r(16) !important;
      }
    }
    .actions {
      right: r(13);
      left: unset;
      gap: r(6);
      .btn {
        padding: r(5) r(10);
        font-size: r(11);
        .icon {
          width: r(12);
          height: r(12);
          margin-left: r(6);
        }
      }
    }
    .menu-toggle {
      margin-left: auto;
    }
  }
}
