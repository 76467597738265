.news-section {
  display: flex;
  flex-direction: column;
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: r(40) 0 r(47);
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: r(30);
  }
  .search-form {
    flex: 0 0 27.15%;
  }
  @media (max-width: 1279px) {
    .title-row {
      flex-direction: column;
      margin-bottom: r(28);
    }
    .search-form {
      flex: 0 0 auto;
      width: 100%;
      margin-top: r(12);
    }
    .grid {
      grid-template-columns: 1fr;
      gap: r(20);
    }
  }
}
