.events-section {
  display: flex;
  flex-direction: column;
  .title-row {
    display: flex;
    align-items: flex-end;
    margin: r(40) 0 r(47);
    .categories-btns {
      margin: 0 auto;
    }
  }
  .content-text {
    margin-top: r(30);
  }
  @media (max-width: 1279px) {
    .title-row {
      flex-direction: column;
      align-items: flex-start;
      .categories-btns {
        margin: r(25) 0 0;
      }
    }
  }
}
.event-card {
  display: flex;
  position: relative;
  background: #fff;
  .img {
    width: 100%;
  }
  .img-col {
    flex: 0 0 31.25%;
  }
  .text-col {
    flex: 1 1 auto;
    padding: r(40) r(50);
  }
  .btn {
    margin-top: r(23);
  }
  &:not(:last-child) {
    margin-bottom: r(30);
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    .img-col {
      flex: 0 0 auto;
    }
    .text-col {
      flex: 0 0 auto;
      padding: r(22);
    }
  }
}
