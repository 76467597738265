.map-section {
  height: calc(100vh - r(84) - r(32));
  position: relative;
  background: var(--map-bg-color) !important;
  overflow: hidden;
  .map-wrap {
    width: 100%;
    height: 100%;
    position: relative;
    transform-origin: 0 0;
    &.active {
      .other-objects,
      .map-bg,
      .main-object:not(.active) {
        filter: blur(1.5px);
      }
    }
  }
  .other-objects,
  .map-bg,
  .main-object {
    transition: filter 0.2s;
  }
  .zoom-root {
    height: 100%;
  }
  .map-bg {
    width: r(2500);
    height: r(1130);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(var(--bg-scale));
    transform-origin: center;
    object-fit: contain;
    object-position: center;
    will-change: transform;
  }
  .map-scheme {
    width: auto;
    height: 100%;
    max-height: r(816);
    margin: auto;
    z-index: 1;
    fill: transparent;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  .main-objects {
    fill: transparent;
    transform-origin: left top;
    pointer-events: none;
    .title {
      font-family: "Pacifico";
      font-size: 24px;
      fill: #4f4646;
      text-rendering: optimizeSpeed;
      transition: fill $tr-time;
    }
    .main-title {
      font-size: 29px;
    }
  }
  .main-object {
    cursor: pointer;
    pointer-events: all;
    &:hover {
      filter: none !important;
      .title {
        fill: $red;
      }
    }
  }
  .description-box {
    position: absolute;
    bottom: r(33);
    left: r(75);
    padding: r(30);
    z-index: 1;
    background: #fff;
    box-shadow: 5px 4px 27px 0px rgba(164, 202, 237, 0.3);
    .title {
      color: $red;
      font-size: r(40);
    }
    .description {
      max-width: r(435);
      margin-top: r(12);
      font-size: r(18);
    }
    .controls {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      gap: r(30);
      margin-top: r(44);
      .arrow {
        .icon {
          width: r(36);
          height: r(15);
          fill: $red;
        }
        &.prev {
          .icon {
            transform: rotate(180deg);
          }
        }
        &:hover {
          .icon {
            fill: #ff7d74;
          }
        }
      }
      .name {
        font-size: r(18);
      }
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: r(20);
      .icon {
        width: r(18);
        height: r(14);
        fill: $black;
      }
      &:hover {
        .icon {
          fill: $red;
        }
      }
    }
    &:not(.active) {
      display: none;
    }
  }
  .zoom-in,
  .zoom-out {
    width: r(45);
    height: r(45);
    position: absolute;
    top: 50%;
    right: r(12);
    background: $red;
    border-radius: 50%;
    &::before,
    &::after {
      content: "";
      display: block;
      width: r(20);
      height: r(2);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #fff;
    }
    &:hover {
      background: #ff7d74;
    }
  }
  .zoom-in {
    transform: translate(0, -55%);
    &::after {
      transform: rotate(90deg);
    }
  }
  .zoom-out {
    transform: translate(0, 55%);
    &::after {
      display: none;
    }
  }
  @media (max-width: 1279px) {
    height: calc(100dvh - r(60));
    .map-scheme {
      width: 100%;
      height: auto;
    }
    .zoom-in,
    .zoom-out {
      width: r(30);
      height: r(30);
      top: r(46);
      right: r(12);
      &::before,
      &::after {
        width: r(13);
      }
    }
    .description-box {
      left: r(10);
      right: r(10);
      bottom: r(15);
      padding: r(12);
      .title {
        font-size: r(20);
      }
      .description {
        max-width: 100%;
        margin-top: r(4);
        font-size: r(14);
      }
      .close {
        padding: r(12);
        .icon {
          width: r(14);
          height: r(11);
          opacity: 0.4;
        }
      }
      .controls {
        margin-top: r(16);
        gap: r(12);
        grid-auto-columns: auto;
        align-items: flex-start;
        .arrow {
          margin-top: r(5);
          .icon {
            width: r(24);
            height: r(10);
          }
        }
        .name {
          font-size: r(14);
        }
      }
    }
  }
}
