.custom-scrollbar {
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-right: r(-24);
  &__wrap {
    height: 100%;
  }
  &__container {
    height: 100%;
  }
  &__content {
    padding: 0 r(24) r(25) 0;
  }
  .os-scrollbar {
    --os-size: #{r(5)};
    --os-handle-bg: #{$red};
    --os-track-bg: #{rgba($red, 0.2)};
    --os-handle-border-radius: #{r(13)};
    --os-track-border-radius: #{r(13)};
  }
  .os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless,
  .os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless.os-scrollbar-rtl {
    bottom: r(25);
  }
}
