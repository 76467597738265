.news-card {
  display: flex;
  min-height: r(250);
  position: relative;
  border-radius: r(10);
  background: #fff;
  .img-col {
    flex: 0 0 40.2%;
    border-radius: r(10);
    overflow: hidden;
    background: $gray;
  }
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .text-col {
    flex: 1 1 auto;
    padding: r(18) r(30);
  }
  .desc {
    margin-top: r(10);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .title {
    margin-top: r(2);
  }
  @media (max-width: 1279px) {
    flex-direction: column;
    .img-col {
      flex: 0 0 auto;
      min-height: r(240);
    }
    .text-col {
      padding: r(22);
      flex: 0 0 auto;
    }
  }
}
