.booking-form {
    --form-border-color: #C8C8C8;

    .form-title {
        margin: 0 0 r(47) r(6);
    }

    .inputs-row {
        &:not(:last-child) {
            margin-bottom: r(18);
        }

        &--3 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: r(16);
        }
    }

    .inputs-group {
        padding-bottom: r(20);
        margin-bottom: r(20);
        border-bottom: 1px solid #C8C8C8;
    }

    .count-row {
        .form-placeholder {
            grid-column: span 3;
            margin-bottom: r(-10);
        }
    }

    .submit-row {
        display: flex;
        align-items: flex-end;

        .personal {
            flex: 0 0 34%;
            margin-left: r(23);
        }

        .submit-btn {
            flex: 0 0 32%;
        }
    }

    .services-select {
        position: relative;

        select {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .add-btn {
        display: flex;
        align-items: center;
        padding-left: r(16);
        font-size: r(14);
        font-weight: 500;
        font-style: italic;
        cursor: pointer;

        .icon {
            width: r(10);
            height: r(10);
            margin-left: r(10);
            fill: #7B7B7B;
        }
    }

    .additional-service {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: r(14) r(20);
        margin-bottom: r(10);
        border: 1px solid $red;
        border-radius: r(10);

        .name {
            margin-right: auto;
            flex: 0 0 70%;
        }

        .delete {
            width: r(46);
            height: r(33);
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #C8C8C8;
            border-radius: r(42);

            .icon {
                width: r(14);
                height: r(17);
                fill: $black;
            }
        }

        .price-wrap {
            display: flex;
            align-items: center;
            margin-right: r(20);
        }

        .price-type {
            margin-left: r(6);
            font-size: r(16);
            font-weight: 500;
            font-style: italic;
            color: rgba($black, .5);
        }
    }

    .guest-info {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: r(16);
        margin: r(10) 0;
        padding: r(14) r(20);
        border-radius: r(10);
        border: 1px solid $red;

        .form-input-wrap {
            flex: 0 0 100%;
        }

        .passport {
            flex: 0 0 26.6%;
        }

        .passport-date {
            flex: 0 0 26.6%;
        }

        .passport-org {
            flex: 1 0 auto;
        }
    }

    @media (max-width: 1279px) {
        .inputs-row {
            &--3 {
                grid-template-columns: 1fr;
            }
        }

        .count-row {
            grid-template-columns: repeat(2, 1fr);

            .form-placeholder {
                grid-column: span 2;
            }
        }

        .additional-service {
            flex-wrap: wrap;

            .name {
                margin-bottom: r(12);
                flex: 0 0 100%;
            }
        }

        .submit-row {
            flex-direction: column;
            align-items: flex-start;

            .personal {
                margin: r(12) 0 0;
                flex: 0 0 auto;
            }
        }

        .guest-info {

            .passport,
            .passport-date {
                flex: 0 0 100%;
            }
        }
    }
}