.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;

    &.is-open {
        display: block;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15vh 0 0;
        background: rgba(46, 46, 46, 0.4);
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: r(12);
        z-index: 2;
        cursor: pointer;
        background: transparent;

        &-icon {
            width: r(18);
            height: r(14);
            fill: $black;
        }

        $this: &;

        &:hover {
            #{$this}-icon {
                fill: $red;
            }
        }
    }

    &__container {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }

    &[aria-hidden="false"] {
        .modal {
            &__overlay {
                animation: mmfadeIn $tr-time cubic-bezier(0, 0, 0.2, 1);
            }

            &__container {
                animation: mmslideIn $tr-time cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }

    &[aria-hidden="true"] {
        .modal {
            &__overlay {
                animation: mmfadeOut $tr-time cubic-bezier(0, 0, 0.2, 1);
            }

            &__container {
                animation: mmslideOut $tr-time cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }

    @media (max-width: 767px) {
        &__overlay {
            padding-left: r(15);
            padding-right: r(15);
        }
    }
}

@keyframes mmfadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-10%);
    }
}