.range-slider {
    padding: 0 r(10);

    .noUi {

        &-value,
        &-marker-large {
            display: none;
        }

        &-horizontal {
            height: auto;

            .noUi-handle {
                width: r(21);
                height: r(21);
                right: r(-10);
                top: r(1);

                &::before,
                &::after {
                    display: none;
                }
            }
        }

        &-handle {
            background: #fff;
            border: 3px solid $red;
            border-radius: 50%;
            box-shadow: none;
        }

        &-target {
            background: transparent;
            border: 0;
            box-shadow: none;
        }

        &-base {
            display: flex;
            align-items: center;
            height: r(21);
        }

        &-pips {
            display: flex;
            justify-content: space-between;
            height: 100%;
            top: 0;

            &-horizontal {
                padding: 0;
            }
        }

        &-connect {
            background: $red;
        }

        &-connects {
            height: r(3);
            background: #939393;
            border-radius: 0;
        }

        &-value {
            position: static;
            font-size: r(18);
            line-height: 1.4;
            color: $red;

            &-horizontal {
                transform: none;
            }
        }

        &-tooltip {
            padding: 0 0 r(8);
            font-size: r(18);
            line-height: 1.4;
            color: $red;
            border: 0;
            background: transparent;
        }

        &-marker {
            background: $red;
        }

        &-marker-horizontal.noUi-marker {
            height: r(16);
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
}