.footer {
  height: r(32);
  color: $gray;
  background: #fff;
  .text {
    margin-right: r(50);
    font-size: r(16);
    line-height: 1.3;
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .vk {
    margin-right: r(50);
    .icon {
      width: r(23);
      height: r(23);
    }
    &:hover {
      filter: drop-shadow(2px 2px 7px rgba(88, 175, 206, 0.4));
    }
  }
  .visually-impaired {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: r(16);
    font-weight: 500;
    color: $black;
    .icon {
      width: r(28);
      height: r(16);
      margin-right: r(12);
      fill: currentColor;
    }
    &:hover {
      color: $red;
    }
  }
}

@media (max-width: 1279px) {
  .footer {
    height: auto;
    padding: r(24) 0 r(19);
    .container {
      flex-direction: column;
      align-items: flex-start;
    }
    .text {
      margin: 0 0 r(16);
      font-size: r(14);
    }
    .visually-impaired {
      margin: 0 0 r(24);
      font-size: r(14);
      .icon {
        width: r(23);
        height: r(13);
      }
    }
    .text,
    .vk {
      order: 1;
    }
    .vk{
      margin: 0 0 r(16);
    }
  }
}

.main-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.widget {
  box-sizing: border-box;
  width: 539px;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  color: #1e1e1c;
}

.widget h1 {
  font-family: "Pacifico", ui-serif;
  font-size: 52px;
  font-weight: 400;
  line-height: 57.2px;
  margin-bottom: 50px;
  color: #1e1e1c;
}

.widget p {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 30px;
  font-family: "Nunito", sans-serif;
  width: 380px;
  color: #1e1e1c;
}

.widget-button {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  line-height: 24.55px;
  font-weight: 500;
  color: #ffffff;
  background: transparent;
  background-color: #e33328;
  border: none;
  border-radius: 42px;
  padding: 8px 16px 8px 16px;
  display: inline-block;
  cursor: pointer;
}

.widget-button:hover {
  background-color: #d0e8f0;
  color: #1e1e1c;
  transition: 0.3s;
}

.main-bg {
  display: none;
}

.main-bg.is-open {
  display: block;
}

.main-bg[aria-hidden="false"] .modal_feedback__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.main-bg[aria-hidden="true"] .modal_feedback__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.modal_feedback__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}