.select-input {
    .show-select {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 1px;
        padding: r(12) r(16) r(12) r(30);
        background: var(--form-bg-color);
        border-radius: r(42);
        pointer-events: none;

        .icon {
            width: r(12);
            height: r(7);
            fill: var(--form-text-color)
        }
    }
}