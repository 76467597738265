.hotels-section {
  display: flex;
  flex-direction: column;
  .title-row {
    display: grid;
    grid-template-columns: 23.75% 1fr;
    align-items: flex-end;
    gap: 0 r(53);
    margin: r(40) 0;
  }
  .hotels-container {
    display: grid;
    grid-template-columns: 23.75% 1fr;
    gap: 0 r(53);
    align-items: flex-start;
  }
  .sidebar {
    padding: r(44) r(26) r(57);
    border: 1px solid #c8c8c8;
    .title {
      position: relative;
      margin-bottom: r(46);
      .cloud {
        width: r(362);
        height: r(87);
        position: absolute;
        left: -4%;
        top: -70%;
        z-index: -1;
        fill: #fff;
      }
    }
    .filters {
      width: 67%;
      .form-input-wrap {
        &:not(:last-child) {
          margin-bottom: r(19);
        }
      }
    }
    .bottom {
      border-top: 1px dashed #c8c8c8;
      margin-top: r(30);
      padding: r(30) 0 0;
    }
    .people-input {
      .wrap {
        width: 100%;
        position: absolute;
        top: calc(100% + r(6));
        left: 0;
        padding: r(8) r(16);
        border-radius: r(20);
        background: #fff;
        --form-border-color: #dbdbdb;
        --form-text-color: #{$red};
        .form-input-wrap {
          display: flex;
          align-items: center;
        }
        .form-input {
          flex: 0 0 r(92);
          margin-right: r(16);
          text-align: center;
        }
        .input-name {
          font-size: r(16);
          font-style: italic;
          font-weight: 500;
        }
      }
      .main-input {
        cursor: pointer;
      }
      .arrow {
        position: absolute;
        bottom: r(16);
        right: r(16);
        width: r(13);
        height: r(7);
        transform: rotate(180deg);
        fill: $red;
        pointer-events: none;
        transition-property: fill, transform;
      }
      &:not(.open) {
        .wrap {
          display: none;
        }
        .arrow {
          fill: $black;
          transform: none;
        }
      }
    }
    .hotel-name {
      margin-bottom: r(22);
      font-style: italic;
      font-weight: 500;
    }
    .price-row {
      display: flex;
      align-items: flex-start;
      .btn {
        margin-left: r(28);
      }
    }
  }
  .main-content {
    height: 100%;
    margin-right: r(-24);
    .custom-scrollbar {
      margin: 0;
    }
  }
}
