.modal-result-message {
  .modal {
    &__container {
      max-width: 28.1%;
      padding: r(50);
      border-radius: r(10);
      background: #fff;
    }
  }
  .description {
    margin-top: r(33);
    font-size: r(20);
  }
  .close-btn {
    margin-top: r(33);
  }
}
