.form {
    &-input {
        display: block;
        width: 100%;
        height: r(41);
        padding: 0 r(16);
        font-size: r(18);
        color: var(--form-text-color);
        font-style: italic;
        font-weight: 500;
        background: var(--form-bg-color);
        border: 1px solid var(--form-border-color);
        border-radius: r(42);
        transition: border-color $tr-time;

        &-name {
            position: absolute;
            height: 1em;
            top: 0;
            bottom: 0;
            right: r(16);
            margin: auto;
            font-size: r(14);
            line-height: 1;
            font-weight: 500;
            font-style: italic;
        }

        &-underline {
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }

        &::placeholder {
            color: var(--form-input-placeholder-color);
        }

        &.error {
            border-color: var(--form-error-color) !important;
        }

        &:focus,
        &:not(:placeholder-shown) {
            +.form-placeholder {
                transform: translateY(-50%) scale(0.6);
                color: var(--form-placeholder-active-color);
            }
        }

        &.absolute {
            padding-top: r(8);

            &::placeholder {
                opacity: 0;
            }
        }
    }

    &-textarea {
        height: r(108);
        padding-top: r(12);
        border-radius: r(20);
        resize: none;
    }

    &-placeholder {
        margin: 0 0 r(3) r(8);
        color: var(--form-placeholder-color);
        font-size: r(16);
        font-style: italic;
        font-weight: 500;
        pointer-events: none;
        transform-origin: left top;
        transition-property: transform;
        transition-duration: $tr-time;

        &.absolute {
            position: absolute;
            left: r(16);
            top: 0;
            bottom: 0;
            height: 1em;
            margin: auto;
            line-height: 1;
        }
    }

    &-input-wrap {
        position: relative;
    }

    &-error {
        color: var(--form-error-color);
    }
}

.personal {
    color: var(--form-personal-color);

    &__link {
        color: var(--form-personal-link-color);
        text-decoration: underline;
    }
}

.ok-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: r(42);
    height: r(29);
    margin: auto;
    transform: scale(var(--form-status-scale));
    fill: var(--form-status-icon-color);
}