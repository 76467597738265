.tours-section {
  display: flex;
  flex-direction: column;
  .title-row {
    display: flex;
    margin: r(35) 0 r(24);
    justify-content: space-between;
  }
  .individual-tour {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 80.8%;
    padding: r(16) r(30);
    border: 1px solid $red;
  }
  .tours-container {
    display: flex;
    justify-content: space-between;
    .cards {
      flex: 0 0 80.8%;
    }
  }
  .filters {
    flex: 0 0 15.65%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 0 r(29);
    .filters-form {
      margin-bottom: auto;
    }
    .contacts {
      .contact-name {
        font-size: r(16);
        font-style: italic;
        font-weight: 500;
      }
      &:not(:last-child) {
        margin-bottom: r(18);
      }
      .contact {
        color: $red;
        font-size: r(18);
        line-height: 1.4;
      }
    }
  }
  @media (max-width: 1279px){
    .title-row{
      flex-direction: column;
      .title{
        margin-bottom: r(30);
      }
    }
    .tours-container{
      flex-direction: column;
    }
    .filters{
      .filters-form{
        order: 1;
        padding: r(15) 0 0;
      }
    }
  }

  @media (max-width: 575px){
    .individual-tour{
      flex-direction: column;
      align-items: stretch;
      .btn{
        margin-top: r(10);
      }
    }
    .filters{
      
    }
  }
}
