.contacts-page {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .left-col {
    padding-left: r(75);
  }
  .map-col {
    background: $light-gray;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    gap: r(16) r(60);
    margin-bottom: r(16);
    .contact-group {
      .head {
        display: inline-flex;
      }
      .content-wrap {
        max-width: r(460);
      }
    }
  }
  .title {
    margin: r(40) 0;
  }
  .contact-group {
    flex: 0 0 auto;
    .head {
      display: flex;
      align-items: center;
      padding: r(10) r(20);
      border-radius: r(10);
      background: #fff;
      cursor: pointer;
      &::before {
        width: r(22);
        height: r(22);
        flex: 0 0 auto;
        margin-right: r(10);
        background: $light-gray;
        border-radius: 50%;
        content: "";
      }
    }
    .name {
      font-style: italic;
      font-weight: 500;
    }
    .content-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: r(8) r(30);
      padding: r(18) r(10) r(14);
    }
    .contact-name {
      font-style: italic;
      font-weight: 500;
      color: #9facb5;
    }
    .contact-value {
    }
    .contact-row {
    }
  }
  .contacts-grid {
    display: flex;
    flex-wrap: wrap;
    gap: r(16);
    padding-bottom: r(30);
    .contact-group {
      width: min-content;
      .head {
        white-space: nowrap;
      }
    }
  }
  @media (max-width: 1279px) {
    grid-template-columns: 100%;
    .left-col {
      padding: 0 r(14);
    }
    .map-col {
      height: r(400);
    }
    .top-row {
      flex-direction: column;
      .contact-group {
        .head {
          display: flex;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .contacts-grid {
      flex-direction: column;
      .contact-group {
        width: auto;
        .head {
          white-space: normal;
        }
      }
    }
  }
}
