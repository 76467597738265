.how-to-get {
  .map-col {
    height: calc(100dvh - #{r(32)} - #{r(84)});
    position: sticky;
    top: 0;
  }
  .paths {
    margin-top: r(85);
    .title {
      margin-bottom: r(52);
    }
    .row {
      display: flex;
      .transport {
        flex: 0 0 24%;
        margin-right: r(27);
      }
      .ways {
        flex: 1 1 auto;
      }
      .way {
        &:not(:last-child) {
          margin-bottom: r(39);
        }
      }
      &:not(:last-child) {
        margin-bottom: r(54);
      }
    }
  }
  @media (max-width: 1279px) {
    .paths {
      margin-top: r(50);
      .title {
        margin-bottom: r(38);
      }
      .row {
        flex-direction: column;
        .transport {
          flex: 0 0 auto;
          margin: 0 0 r(12);
        }
        &:not(:last-child) {
          margin-bottom: r(32);
        }
      }
    }
  }
}
.how-to-get-marker {
  transform: translate(0, -100%);
  .icon {
    width: r(275);
    height: r(60);
  }
}
