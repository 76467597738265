.notifications-list {
    display: flex;
    flex-direction: column;
    gap: r(50);
    position: fixed;
    bottom: r(65);
    right: r(75);
    z-index: 3;

    .notification {
        position: relative;
        max-width: r(470);
        padding: r(30);
        background: #fff;
        box-shadow: 5px 4px 27px 0px rgba(164, 202, 237, 0.3);

        .title {
            color: $red;
            font-size: r(40);
            line-height: 1;
        }

        .description {
            margin-top: r(12);
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: r(20);

            .icon {
                width: r(18);
                height: r(14);
                fill: $black;
            }
        }
    }

    @media (max-width: 1279px) {
        position: static;
        gap: r(15);
        padding: r(15);
    }
}