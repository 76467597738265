.search-form {
  position: relative;
  --form-border-color: #{$red};
  --form-text-color: #{$black};
  --form-error-color: #{$red};
  --form-placeholder-color: #5e5f5e;
  --form-placeholder-active-color: #5e5f5e;
  --form-status-icon-color: #{$white};
  --form-status-scale: 0.6;
  .submit {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
}
