.btn-red-mrg {
  margin-bottom: 0.8rem;;
}

.main-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #fff;
  padding: r(148) 0 r(32);
  .container {
    display: grid;
    grid-template-columns: 53.7% auto;
    gap: 0 r(90);
  }
  .menu {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    margin-bottom: r(52);
    .parent {
      display: block;
      font-size: r(20);
      font-weight: 700;
    }
    .child {
      display: block;
      &:not(:last-child) {
        margin-bottom: r(12);
      }
    }
    .item {
      .parent {
        &:not(:last-child) {
          margin-bottom: r(16);
        }
      }
      &:not(:last-child) {
        margin-bottom: r(34);
      }
    }
  }
  .search {
    position: relative;
    margin-top: auto;
    .input {
      width: 100%;
      height: r(45);
      padding: 0 r(24);
      border: 0;
      background: #f4f7f9;
      &::placeholder {
        font-size: r(18);
        color: #828282;
      }
    }
    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 0 r(24) 0;
      .icon {
        width: r(26);
        height: r(26);
        fill: transparent;
        stroke: $black;
      }
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    gap: r(16);
  }
  .left-col {
    display: flex;
    flex-direction: column;
  }
  .right-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: r(10);
  }
  .action-block {
    padding: r(8);
    position: relative;
    min-height: r(383);
    transition: box-shadow $tr-time;
    .icon {
      position: absolute;
    }
    &.book-trip {
      background: #f4f7f9;
      .icon {
        width: r(200);
        height: r(331);
        right: r(22);
        bottom: r(-16);
      }
    }
    &.work-hours {
      background: #e7edef;
      .icon {
        width: r(231);
        height: r(358);
        right: r(22);
        bottom: r(-16);
      }
    }
    &:hover {
      box-shadow: 5px 4px 27px 0px rgba(164, 202, 237, 0.3);
    }
  }
  .other-pages {
    margin-top: r(50);
  }
  &:not(.open) {
    display: none;
  }
  @media (min-width: 1280px){
    .mobile-col{
      display: none;
    }
  }
  @media (max-width: 1279px) {
    padding: r(91) 0 r(28);
    .right-col {
      display: none;
    }
    .main-tel{
      margin-top: r(20);
    }
    .container {
      grid-template-columns: 100%;
      gap: 0;
    }
    .mobile-col{
      order: 1;
    }
    .menu {
      order: 1;
      grid-template-columns: 1fr;
      margin: r(20) 0 0;
      gap: r(18);
      .item {
        .parent {
          &:not(:last-child) {
            margin-bottom: r(10);
          }
        }
      }
      .parent {
        font-size: r(16);
      }
      .child {
        font-size: r(14);
      }
    }
    .other-pages {
      margin-top: r(18);
    }
  }
}
