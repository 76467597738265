.work-schedule {
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: r(40);
    .buttons {
      display: flex;
      gap: r(10);
      .btn {
        font-style: italic;
      }
      .btn-red-border {
        --text-color: #5e5f5e;
      }
    }
    .description {
      font-size: r(16);
      font-style: italic;
      font-weight: 500;
    }
  }
  .schedule-table {
    margin-top: r(50);
    table {
      width: 100%;
      border-collapse: collapse;
      span {
        color: $red;
        font-style: italic;
        font-weight: 500;
      }
      small {
        font-size: r(14);
        line-height: normal;
      }
      thead {
        font-size: r(18);
        color: $red;
        line-height: 1.4;
        font-weight: 500;
        font-style: italic;
        td {
          padding: r(3) r(20) r(24);
          white-space: nowrap;
        }
      }
      td {
        max-width: r(280);
        padding: r(16) r(20);
        font-size: r(16);
        border: 1px solid #d3d4d4;
        border-top: 0;
        vertical-align: top;
        &:first-child {
          color: $red;
          font-style: italic;
          font-weight: 500;
          span {
            color: $black;
          }
        }
      }
    }
  }
  @media (min-width: 1280px) {
    .schedule-table {
      table {
        td {
          &:first-child {
            padding-left: r(75) !important;
          }
        }
      }
    }
  }
  @media (max-width: 1279px) {
    .title-row {
      flex-direction: column;
      align-items: flex-start;
      .buttons {
        margin: r(25) 0 0;
        order: 1;
      }
      .description {
        margin-top: r(20);
      }
    }
    .schedule-table {
      overflow: auto;
    }
  }
}
