.hotel-page {
  .detail-text {
    margin: r(40) 0 0;
  }
  .hotel-features {
    columns: 3 auto;
    column-gap: r(120);
    .group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      break-inside: avoid;
      margin-bottom: r(20);
      .name {
        margin-bottom: r(8);
        font-style: italic;
        font-weight: 500;
      }
    }
    .hotel-prop {
      &:not(:last-child) {
        margin-bottom: r(4);
      }
    }
  }
}
