.personal-account {
  padding: r(40) 0 r(50);
  .title {
    margin-bottom: r(40);
  }
  .grid {
    display: grid;
    grid-template-columns: 24.7% 1fr;
  }
  .personal-form {
    padding: 0 r(34) 0 0;
    border-right: 1px solid #c8c8c8;
  }
  .fields-group {
    display: flex;
    flex-direction: column;
    gap: r(10);
    margin-top: r(26);
    border: 0;
    &:not(:last-child) {
      margin-bottom: r(50);
    }
    [type="submit"] {
      align-self: flex-start;
    }
  }
  .tab {
    display: inline-flex;
    align-items: center;
    padding: r(10) r(20);
    font-size: r(18);
    line-height: 1.4;
    font-style: italic;
    font-weight: 500;
    background: #fff;
    .icon {
      width: r(20);
      height: r(20);
      margin-right: r(10);
      fill: #7b7b7b;
    }
  }
}
