.date-input {
    .show-calendar {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 1px;
        padding: r(12) r(16) r(12) r(30);
        background: var(--form-bg-color);
        border-radius: r(42);

        .icon {
            width: r(15);
            height: r(17);
            fill: var(--form-text-color)
        }
    }
}