@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,700;1,400;1,500&family=Pacifico&display=swap");

@import "./base/variables.scss";
@import "./base/functions.scss";
@import "./base/reset.scss";
@import "./base/utils.scss";
@import "./base/themes.scss";

@import "./forms/forms.scss";
@import "./forms/preloader.scss";
@import "./forms/default-form-colors.scss";
@import "./forms/date-input.scss";
@import "./forms/select-input.scss";

@import "./modals/modal.scss";
@import "./modals/modal-result-message.scss";
@import "./modals/modal-tour.scss";

@import "./components/header.scss";
@import "./components/footer.scss";
@import "./components/map-section.scss";
@import "./components/breadcrumbs.scss";
@import "./components/news-card.scss";
@import "./components/custom-scrollbar.scss";
@import "./components/search-form.scss";
@import "./components/contacts-page.scss";
@import "./components/main-menu.scss";
@import "./components/objects-page.scss";
@import "./components/news-section.scss";
@import "./components/how-to-get.scss";
@import "./components/work-schedule.scss";
@import "./components/programms-list.scss";
@import "./components/events-section.scss";
@import "./components/categories-btns.scss";
@import "./components/filters-form.scss";
@import "./components/range-slider.scss";
@import "./components/sections-grid.scss";
@import "./components/hotels-script.scss";
@import "./components/booking-form.scss";
@import "./components/notifications-list.scss";

@import "./components/canvas-test.scss";

@import "./hotels/hotels-section.scss";
@import "./hotels/hotel-page.scss";
@import "./hotels/hotel-card.scss";
@import "./hotels/hotel-prop.scss";

@import "./cart/dedmoroz-cart.scss";

@import "./personal-account/personal-account.scss";

@import "./tours/tours-section.scss";
@import "./tours/tour-card.scss";

@import "./prices/prices-section.scss";

@import "./order/dedmoroz-order.scss";

@import "./text-page/text-page.scss";
@import "./text-page/content-block.scss";
@import "./text-page/content-text.scss";
@import "./text-page/content-image.scss";
@import "./text-page/content-gallery.scss";
@import "./text-page/content-gallery-item.scss";
@import "./text-page/content-file-row.scss";
@import "./text-page/content-title-underline.scss";