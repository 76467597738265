.dedmoroz-order {
  padding: r(40) 0 r(100);
  .title {
    margin-bottom: r(40);
  }
  .warning {
    color: $red;
    font-style: italic;
  }
  .order-section {
    --form-border-color: #c8c8c8;
    .section-head {
      padding-left: r(8);
      margin-bottom: r(24);
    }
    padding: r(20) r(20) r(26);
    background: #fff;
    border-radius: r(10);
    &:not(:last-child) {
      margin-bottom: r(20);
    }
  }
  .top {
    display: flex;
    flex-wrap: wrap;
    padding: r(18) r(20);
    gap: 0 r(14);
    .text {
      font-style: italic;
      font-weight: 500;
    }
    .delimeter {
      color: $red;
    }
  }
  .contacts {
    .fields {
      width: 73.4%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: r(14) r(30);
    }
    .warning {
      margin-top: r(24);
    }
  }
  .payment {
    .section-head {
      .payment-warning {
        margin-top: r(16);
      }
    }
    .payment-warning {
      font-size: r(16);
      font-style: italic;
      font-weight: 500;
    }
    .systems {
      display: flex;
      gap: r(3);
      flex-wrap: wrap;
      margin-top: r(8);
      .system-icon {
        width: r(70);
        height: r(30);
      }
      .system {
        display: flex;
        align-items: center;
      }
      .system-name {
        margin-left: r(8);
        font-size: r(16);
        font-style: italic;
        font-weight: 500;
      }
    }
    .payment-method {
      padding-top: r(20);
      margin-top: r(20);
      border-top: 1px solid #c8c8c8;
      .payment-warning {
        margin-top: r(4);
      }
      .warning {
        margin-top: r(14);
      }
      .content-text {
        width: 68%;
        margin-top: r(14);
      }
      .select-btn {
        margin-top: r(14);
      }
    }
  }
  .submit-btn {
    width: 100%;
    height: r(74);
    font-size: r(28);
    font-weight: 700;
  }
  @media (max-width: 1279px) {
    .contacts {
      .fields {
        width: 100%;
        grid-template-columns: 1fr;
        gap: r(10) r(30);
      }
    }
    .payment {
      .payment-method {
        .content-text {
          width: 100%;
        }
      }
    }
  }
}
