.sections-grid {
  padding: r(40) 0;
  .title {
    margin-bottom: r(54);
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: r(24) r(30);
  }
  .section-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: r(250);
    padding: r(14) r(20);
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: #d9d9d9;
    border-radius: r(10);
    .name {
      width: 60%;
      color: #fff;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background: linear-gradient(to top, $black, transparent 40%);
    }
  }
  @media (max-width: 1279px) {
    .grid {
      grid-template-columns: 1fr;
    }
    .section-item {
      height: r(230);
    }
  }
}
