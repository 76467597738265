.modal-tour {
    .modal {
        &__container {
            max-width: 540px;
            padding: r(40);
            border-radius: r(10);
            background: #fff;
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: r(20);
    }

    .submit-row {
        display: flex;
        gap: r(20);
    }

    .submit-btn {
        flex: 0 0 auto;
    }

    @media (max-width: 575px) {
        .modal {
            &__container {
                padding: r(20);
            }
        }

        .submit-row {
            flex-direction: column;
            gap: r(10);
        }
    }
}