.dedmoroz-cart {
  padding: r(40) 0 r(45);
  .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 r(40);
  }
  .clear-cart {
    font-style: italic;
    font-weight: 500;
  }
  .grid {
    display: grid;
    grid-template-columns: 83.1% 1fr;
    gap: r(30);
  }
  .sidebar {
    align-self: flex-start;
    padding: r(30);
    border: 1px solid #c8c8c8;
    border-radius: r(10);
    .title {
      margin-bottom: r(30);
    }
    .total {
      padding: r(20) 0 0;
      margin-top: r(30);
      border-top: 2px dashed #c8c8c8;
    }
    .price-block {
      .name {
        font-size: r(14);
        font-style: italic;
        font-weight: 500;
      }
      &:not(:last-child) {
        margin-bottom: r(14);
      }
    }
  }
  .cart-item {
    display: flex;
    padding: r(20) r(30);
    border: 1px solid #c8c8c8;
    border-radius: r(10);
    &:not(:last-child) {
      margin-bottom: r(10);
    }
    .name {
      flex: 0 0 40%;
      position: relative;
      padding: r(27) r(30) r(24) 0;
      .cloud {
        width: r(362);
        height: r(87);
        position: absolute;
        left: r(-11);
        top: 0;
        z-index: -1;
        fill: #fff;
      }
    }
    .left {
      flex: 0 0 84.52%;
    }
    .top {
      display: flex;
    }
    .props {
      display: flex;
      align-items: flex-start;
      flex: 1 1 auto;
      gap: r(25);
    }
    .people-props {
      .fields {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: r(10);
      }
      .prop-value {
        width: r(92);
        margin-right: r(16);
        color: $red;
      }
      .prop {
        display: flex;
        align-items: center;
      }
      .prop-desc {
        font-size: r(14);
        line-height: 1;
        font-weight: 500;
        font-style: italic;
      }
      .prop-price {
        font-size: r(18);
        line-height: 1;
        font-weight: 500;
        font-style: italic;
        color: $red;
      }
    }
    .prop {
      flex: 0 0 auto;
    }
    .prop-name {
      margin: 0 0 r(6) r(16);
      font-style: italic;
      font-weight: 500;
    }
    .prop-value {
      padding: r(8) r(16);
      text-align: center;
      font-style: italic;
      font-weight: 500;
      background: #fff;
      border-radius: r(42);
      &.long {
        width: r(179);
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1 1 auto;
      padding: r(4) r(10) r(4) r(20);
      border-left: 2px dashed #c8c8c8;
    }
    .price {
      font-size: r(42);
      line-height: 1;
    }
    .buttons-row {
      margin-top: r(27);
    }
    .product-name {
      font-style: italic;
      font-weight: 500;
    }
    .reservation-bottom {
      padding: r(20) 0 0;
      display: flex;
      .left-bottom {
        flex: 0 0 auto;
      }
      .bottom-info {
        display: flex;
        align-items: center;
        .icon {
          width: r(24);
          height: r(24);
          margin-right: r(14);
          flex: 0 0 auto;
          fill: #c8c8c8;
        }
        .link {
          text-decoration: underline;
        }
      }
      .payment-info {
        align-items: flex-start;
        flex: 0 0 36%;
        margin-left: r(140);
      }
      .cancel-rules {
        margin-top: r(16);
      }
    }
    .additional-services {
      padding: r(37) r(20) 0 0;
      .service {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: r(14) r(20);
        background: rgba($red, 0.04);
        border: 1px solid $red;
        border-radius: r(10);
        .added-btn {
          pointer-events: none;
          --bg-color: #{$white};
          .icon {
            width: r(15);
            height: r(15);
            margin-right: r(10);
            fill: $red;
          }
        }
        .price-row {
          display: flex;
          align-items: center;
          margin-right: r(20);
          .service-price-type {
            margin-left: r(6);
            opacity: 0.5;
            font-weight: 500;
            font-style: italic;
          }
        }
        .service-name {
          flex: 0 0 70%;
          margin-right: auto;
        }
        &:not(:last-child) {
          margin-bottom: r(10);
        }
      }
    }
    .services-title {
      margin: 0 0 r(12) r(5);
      font-size: r(14);
      font-style: italic;
      font-weight: 500;
    }
  }
  @media (max-width: 1279px) {
    .grid {
      grid-template-columns: 1fr;
    }
    .title-row {
      margin-bottom: r(30);
    }
    .sidebar {
      padding: r(20);
      .title {
        margin-bottom: r(24);
      }
      .total {
        margin-top: r(26);
        padding-top: r(16);
      }
    }
    .cart-item {
      display: block;
      padding: r(20);
      .top {
        flex-direction: column;
      }
      .name {
        padding: r(14) r(30) r(14) 0;
        flex: 0 0 auto;
        margin-bottom: r(14);
        .cloud {
          width: r(319);
          height: r(77);
        }
      }
      .props {
        flex-wrap: wrap;
        flex: 0 0 auto;
        gap: r(20);
      }
      .people-props {
        flex: 0 0 100%;
        .fields {
          grid-template-columns: 1fr;
        }
      }
      .product-name {
        flex: 0 0 100%;
      }
      .total {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        padding: r(14) 0 0;
        border-top: 2px dashed #c8c8c8;
        border-left: 0;
      }
      .buttons-row {
        margin-top: r(10);
      }
      .additional-services {
        padding: r(28) 0 r(20);
        .service {
          flex-wrap: wrap;
          justify-content: space-between;
          .service-name {
            margin-bottom: r(14);
            flex: 0 0 100%;
          }
          .price-row {
            margin-right: 0;
          }
        }
      }
    }
  }
}
