.programms-list {
    display: flex;
    flex-direction: column;

    .news-container {
        display: flex;
        flex-wrap: wrap;
    }

    .title-row {
        display: flex;
        margin: r(40) 0;

        .btns {
            align-self: flex-end;
            margin-left: 24%;
        }
    }

    .sidebar {
        flex: 0 0 17%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: r(25);
    }

    .contacts {
        .contact-name {
            font-size: r(16);
            font-style: italic;
            font-weight: 500;
        }

        .contact-value {
            display: block;
            font-size: r(20);
            color: $red;
        }

        &:not(:last-child) {
            margin-bottom: r(20);
        }
    }

    .posts {
        height: 100%;
        flex: 0 0 80%;
        margin-left: auto;
    }

    .individual {
        margin-bottom: auto;
        padding: r(16) r(20);
        background: #fff;

        .btn {
            margin-top: r(20);
        }
    }

    @media (max-width: 1279px) {
        .news-container {
            flex-direction: column;
        }

        .title-row {
            flex-direction: column;

            .btns {
                margin: r(30) 0 0;
                align-self: flex-start;
            }
        }

        .individual {
            margin-bottom: r(30);

            .btn {
                margin-top: r(12);
            }
        }
    }
}

.programm-card {
    display: flex;
    background: #fff;

    .content-text {
        margin-top: r(24);
    }

    .img-col {
        flex: 0 0 35.55%;

        .img {
            width: 100%;
        }
    }

    .text-col {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: r(38) r(30) r(30) r(51);
    }

    .price-row {
        padding-top: r(25);
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .price {
        display: flex;
        align-items: flex-end;

        .unit {
            margin-left: r(8);
            font-size: r(16);
            font-style: italic;
            font-weight: 500;
        }
    }

    .video-link {
        align-self: flex-start;
        margin-top: auto;
    }

    &:not(:last-child) {
        margin-bottom: r(20);
    }

    @media (max-width: 1279px) {
        flex-direction: column;

        .text-col {
            padding: r(22);
        }

        .price-row {
            padding-top: r(15);
            flex-direction: column;
            align-items: flex-start;

            .btn {
                margin-top: r(12);
            }
        }

        .name {
            font-size: r(22);
        }

        .video-link {
            margin-top: r(12);
        }
    }
}