.default-form-colors {
    --form-bg-color: #fff;
    --form-border-color: #C8C8C8;
    --form-text-color: #{$black};
    --form-error-color: #{$red};
    --form-placeholder-active-color: #{$black};
    --form-placeholder-color: #{$black};
    --form-input-placeholder-color: #c8c8c8;
    --form-personal-color: #{rgba($black, 0.5)};
    --form-personal-link-color: #{$black};
    --form-status-icon-color: #{$white};
    --form-status-scale: 0.8;
}

@media (max-width: 1279px) {
    .default-form-colors {
        --form-status-scale: 0.45;
    }
}