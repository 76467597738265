.hotel-card {
  display: flex;
  position: relative;
  overflow: hidden;
  &.list {
    border: 1px solid #c8c8c8;
  }
  .img-col {
    width: r(477);
    flex: 0 0 auto;
  }
  .text-col {
    padding: r(25) r(25) r(20);
    flex: 1 1 auto;
  }
  .img {
    width: 100%;
    height: r(347);
  }
  .price-row {
    display: flex;
    align-items: flex-start;
    margin-top: r(32);
  }
  .price {
    margin-right: r(28);
  }
  .main-props {
    display: flex;
    gap: r(30);
    margin: r(20) 0;
  }

  .other-props {
    display: flex;
    flex-wrap: wrap;
    gap: r(10);
    width: 78.2%;
  }
  &:not(:last-child) {
    margin-bottom: r(30);
  }
  .pagination {
    display: flex;
    gap: r(10);
    position: absolute;
    bottom: r(8);
    left: r(10);
    right: r(10);
    z-index: 1;
    .swiper-pagination-bullet {
      height: r(4);
      flex: 1 1 auto;
      background: #c8c8c8;
      border-radius: r(10);
      &.swiper-pagination-bullet-active {
        background: $red;
      }
    }
  }
  .btn {
    &.chosen {
      --bg-color: transparent;
      --text-color: #{$black};
    }
    .ok {
      width: r(15);
      height: r(13);
      margin-right: r(10);
      fill: $red;
    }
  }
}
