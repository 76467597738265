.text-page {
    display: grid;
    grid-template-columns: 49.2% 1fr;
    gap: r(30);

    .left-col {
        padding-left: r(75);
    }

    .breadcrumbs {
        margin-bottom: r(40);
    }

    .img-wrap {
        height: calc(100dvh - #{r(32)} - #{r(84)});
        position: sticky;
        top: 0;
        background: #d9d9d9;

        &.mobile {
            display: none;
        }

        .img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .booking-col {
        padding: r(93) r(75) r(59);
        background: #fff;
    }

    @media (max-width: 1279px) {
        grid-template-columns: 100%;

        .img-wrap {
            display: none;
            position: static;
            margin-bottom: r(28);

            &.mobile {
                display: block;
            }
        }

        .booking-col {
            padding: r(30) r(14);
        }

        .left-col {
            padding: 0 r(14);
        }
    }
}