.hotel-prop {
  display: flex;
  align-items: center;
  font-size: r(18);
  flex: 0 0 auto;
  .icon {
    width: r(16);
    height: r(16);
    margin-right: r(8);
  }
  sup {
    font-size: 0.6em;
  }
  &.pill {
    padding: r(4) r(10);
    background: #fff;
    border-radius: r(39);
  }
}
